import { useEffect } from "react";
import { useRouteError, useAsyncError } from "react-router-dom";
import { ClockMIcon } from "@alfalab/icons-glyph/ClockMIcon";

import { logger } from "@/shared/logger";
import { APIError } from "@/shared/error";

import { ErrorPage } from "./ErrorPage";

type PageErrorProps = {
	icon?: React.ReactNode;
	actions: Array<{
		view?: "primary" | "secondary";
		label: string;
		callback(): void;
	}>;
};

export const RouterError: React.FC<PageErrorProps> = ({ actions, icon }) => {
	const routeError = useRouteError() as Error;
	const asyncError = useAsyncError() as Error;

	const error = routeError || asyncError;

	useEffect(() => {
		logger.error(error.message, error);
	}, []);

	const defaultIcon = <ClockMIcon color="black" />;

	if (error instanceof APIError && error.type === "message") {
		return (
			<ErrorPage
				icon={icon || defaultIcon}
				title={error.message}
				message={error.advice}
				actions={actions}
				details={error.system}
			/>
		);
	}

	throw error;
};
