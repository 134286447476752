import ScrollContainer from "react-indiana-drag-scroll";
import { Tag } from "@alfalab/core-components/tag";
import { Typography } from "@alfalab/core-components/typography";
import cx from "classnames";

import styles from "./HorizontalSelector.module.css";

export type SelectorItemData = {
	id: string;
	label: string;
	subLabel?: string;
	description?: string;
};

type ItemProps = {
	label: string;
	subLabel?: string;
	description?: string;
	checked?: boolean;
	onClick?: () => void;
};

const Item: React.FC<ItemProps> = ({
	label,
	subLabel,
	description,
	checked,
	onClick,
}) => (
	<div className={styles.item}>
		<Tag
			size="xxs"
			view="filled"
			checked={checked}
			onClick={onClick}
			className={styles.tag}
			childrenClassName={styles.tagLabel}
		>
			{label}
			{subLabel && (
				<Typography.Text
					view="secondary-small"
					color={checked ? undefined : "secondary"}
				>
					{subLabel}
				</Typography.Text>
			)}
		</Tag>

		{description && (
			<Typography.Text view="secondary-small" color="secondary">
				{description}
			</Typography.Text>
		)}
	</div>
);

type HorizontalSelectorProps = {
	data: Array<SelectorItemData>;
	selected?: SelectorItemData["id"] | Array<SelectorItemData["id"]> | null;
	onSelect?: (value: SelectorItemData["id"]) => void;
	className?: string;
};

export const HorizontalSelector: React.FC<HorizontalSelectorProps> = ({
	data,
	selected,
	onSelect,
	className,
}) => {
	return (
		<ScrollContainer className={cx(styles.container, className)}>
			{data.map(({ id, ...item }) => (
				<Item
					key={id}
					{...item}
					checked={
						selected
							? Array.isArray(selected)
								? selected.includes(id)
								: id === selected
							: false
					}
					onClick={() => onSelect?.(id)}
				/>
			))}
		</ScrollContainer>
	);
};
