import { useEffect, useRef, useState } from "react";

export function useCountDown() {
	const [count, setCount] = useState(0);
	const intervalRef = useRef<Timer>(undefined);

	const start = (args: {
		start: number;
		end: number;
		interval: number;
		onEnd?: () => void;
	}) => {
		const startedAt = new Date();

		const computeCount = () => {
			const now = new Date();
			const delta = now.getTime() - startedAt.getTime();

			const diff = args.end - args.start - delta;

			if (diff <= 0) {
				setCount(0);
				clearInterval(intervalRef.current);
				args.onEnd?.();
			} else {
				setCount(diff);
			}
		};

		computeCount();

		intervalRef.current = setInterval(() => {
			computeCount();
		}, args.interval);
	};

	const reset = () => {
		clearInterval(intervalRef.current);
		setCount(0);
	};

	useEffect(() => {
		return () => {
			clearInterval(intervalRef.current);
		};
	}, []);

	const controls = {
		start,
		reset,
	};

	return [count, controls] as const;
}
