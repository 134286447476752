import { Minute } from "@/shared/utils";
import { ENV } from "@/shared/constants";

import { post } from "../fetch";
import { getCachedData, cacheData } from "../cache";

import type { SelectionEvent } from "./selection";

export type City = {
	cityId: number;
	cityName: string;
};

export type AllCities = {
	countryId: number;
	countryName: string;
	cities: Array<{
		id: number;
		name: string;
		region: string;
		towns?: Array<{
			id: number;
			name: string;
		}>;
	}>;
};

export type GetAllCities = AllCities[];

export async function getAllCities() {
	return post<GetAllCities>("/get_all_cities", {});
}

export type SliderItem = {
	actionId: string;
	age: string | null;
	button: string;
	cityId: string;
	countryId: string;
	dates: string;
	eventId: string;
	id: number;
	picture: string;
	place: string;
	subtitle: string;
	description: string;
	price: { min: number };
	min: number;
	title: string;
	type: string;
	venueId: number;
	video: null;
	cashback?: number;
	pageUrl?: string;
	minCashback: number;
	maxCashback: number;
	url: string | null;
};

export type Selection = {
	id: number;
	name: string;
	short_name: string;
	events: SelectionEvent[];
};

export type Genre = {
	genreId: number;
	genreName: string;
};

export type Venue = {
	venueId: number;
	venueName: string;
};

// export type CityVenue = {
// 	cityId: number;
// 	cityName: string;
// 	venueId: number;
// 	venueName: string;
// 	address: string;
// };

export type Action = {
	kindId: number;
	kindName: string;
	actionId: number;
	actionName: string;
	smallPosterKassir: string;
	storiesPosterKassir: string;
	advPosterKassir: string;
	duration: number;
	minPrice: number;
	maxPrice: number;
	from: string;
	to: string;
	time: string;
	age: string;
	menu: Array<{
		menuId: number;
		menuName: string;
	}>;
	fanIdRequired: null;
	kdp: boolean;
	venues: Record<
		number,
		{
			cityId: number;
			cityName: string;
			venueId: number;
			venueName: string;
			href: string;
		}
	>;
	genres: Array<Genre>;
	cashback: number;
	minCashback: number;
	maxCashback: number;
	actionDescription: string | null;
	type: "cinema" | "common";
};

export type ActionsByCity = {
	countryId: number;
	countryName: string;
	cityId: number;
	cityName: string;
	where: string;
	cities: Array<{
		cityId: number;
		cityName: string;
	}>;
	venues: Record<number, Venue>;
	kinds: Array<{
		kindId: number;
		kindName: string;
	}>;
	genres: Array<Genre>;
	slider: Array<SliderItem>;
	selections: Array<Selection>;
	actions: Record<number, Action>;
	menu: Array<{
		menuId: number;
		menuName: string;
	}>;
	new_orders: {
		orders: number;
		time: number;
	};

	parentCityId?: number;
};

type GetAllActionsByCityResult = ActionsByCity;

export async function getAllActionsByCity(args: { cid: number | string }) {
	const cachedData = await getCachedData<GetAllActionsByCityResult>(
		`getAllActionsByCity-${args.cid}`,
		Minute * 6,
	);

	if (cachedData) return cachedData;

	const result = await post<GetAllActionsByCityResult>(
		"/get_all_actions_by_city",
		{
			cid: args.cid,
		},
	).then((data) => {
		if (ENV === "prod") {
			const excludedNames = ["черная пятница!", "чёрная пятница!"];

			const selections = data.selections.filter(
				(selection) => !excludedNames.includes(selection.name.toLowerCase()),
			);

			return {
				...data,
				selections,
			};
		}

		return data;
	});

	await cacheData(`getAllActionsByCity-${args.cid}`, result);

	return result;
}

export async function setCity(args: { cityId: string | number }) {
	return post<"Ok" | false>("/set_city", {
		cid: String(args.cityId),
	});
}
