import { useMemo, createContext, useContext } from "react";
import { startOfDay, isWithinInterval } from "date-fns";

import { useCityFiltersContext } from "@/shared/lib";
import { formatString } from "@/shared/utils";
import type { City, Action, ActionsByCity } from "@/shared/api";

type CityActionsContextValue = {
	city: City & { where: string; parentCityId?: number };
	genres: Array<ActionsByCity["genres"][number]>;
	venues: Array<ActionsByCity["venues"][number]>;
	menus: Array<ActionsByCity["menu"][number]>;
	actions: Array<
		Omit<Action, "venues"> & {
			venues: Array<{
				cityId: number;
				cityName: string;
				venueId: number;
				venueName: string;
				href: string;
			}>;
		}
	>;
	unfilteredActions: Array<
		Omit<Action, "venues"> & {
			venues: Array<{
				cityId: number;
				cityName: string;
				venueId: number;
				venueName: string;
				href: string;
			}>;
		}
	>;
	slider: Array<ActionsByCity["slider"][number]>;
	selections: Array<ActionsByCity["selections"][number]>;
	cities: Array<ActionsByCity["cities"][number]>;
	totalResultsCount: number;
	newOrders: {
		orders: number;
		time: number;
	};
};

const CityActionsContext = createContext<CityActionsContextValue | null>(null);

export function useCityActionsContext() {
	const context = useContext(CityActionsContext);

	if (!context) {
		throw new Error("Missing CityActionsContext.Provider in the tree");
	}

	return context;
}

type CityActionsProviderProps = {
	data: ActionsByCity;
	children: React.ReactNode;
};

export const CityActionsProvider: React.FC<CityActionsProviderProps> = ({
	data,
	children,
}) => {
	const menuFilter = useCityFiltersContext((state) => state.menu);
	const genresFilter = useCityFiltersContext((state) => state.genres);
	const venuesFilter = useCityFiltersContext((state) => state.venues);
	const dateFilter = useCityFiltersContext((state) => state.date);

	const city = useMemo<City & { where: string }>(() => {
		return {
			cityId: data.cityId,
			cityName: data.cityName,
			where: data.where,
			parentCityId: data.parentCityId,
		};
	}, [data]);

	const unfilteredActions = useMemo(() => {
		return Object.values(data.actions).map((action) => ({
			...action,
			venues: Object.values(action.venues),
		}));
	}, [data.actions]);

	const filteredActions = unfilteredActions.filter((action) => {
		// venues filter
		if (
			venuesFilter.length > 0 &&
			!venuesFilter.some((venueId) => {
				return action.venues
					.map(({ venueId }) => venueId)
					.includes(Number(venueId));
			})
		) {
			return false;
		}

		// menu filter
		if (
			menuFilter &&
			!action.menu
				.map(({ menuId }) => String(menuId))
				.includes(String(menuFilter))
		) {
			return false;
		}

		// genres filter
		if (
			genresFilter.length > 0 &&
			!genresFilter.some((genreId) => {
				return Object.values(action.genres)
					.map(({ genreId }) => genreId)
					.includes(genreId);
			})
		) {
			return false;
		}

		// date filter
		if (dateFilter) {
			const [dateFrom, dateTo] = dateFilter;

			const interval = {
				start: new Date(action.from),
				end: new Date(action.to),
			};

			if (dateFrom && dateTo) {
				return (
					isWithinInterval(startOfDay(dateFrom), interval) ||
					isWithinInterval(startOfDay(dateTo), interval)
				);
			}

			return isWithinInterval(startOfDay(dateFrom), interval);
		}

		return true;
	});

	return (
		<CityActionsContext.Provider
			value={{
				city,
				cities: data.cities,
				genres: data.genres,
				venues: Object.values(data.venues).map(({ venueName, ...venue }) => ({
					...venue,
					venueName: formatString(venueName),
				})),
				slider: data.slider,
				menus: data.menu,
				selections: data.selections,
				totalResultsCount: filteredActions.length,
				actions: filteredActions,
				unfilteredActions,
				newOrders: data.new_orders,
			}}
		>
			{children}
		</CityActionsContext.Provider>
	);
};
