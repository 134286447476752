import { useEffect } from "react";
import { InformationCircleMIcon } from "@alfalab/icons-glyph/InformationCircleMIcon";

import { InfoPage, type InfoPageProps } from "@/shared/ui/info-page";
import { useMultiClick } from "@/shared/hooks";
import { trackScreenView } from "@/shared/metrica";
import { ENV } from "@/shared/constants";

export const ErrorPage: React.FC<
	Pick<InfoPageProps, "actions"> & {
		icon?: React.ReactNode;
		title?: string;
		message?: string;
		details?: string;
	}
> = ({ icon, title, message, actions, details }) => {
	const { isClicked: isShowDetails, handleClick } = useMultiClick(3);

	useEffect(() => {
		document.addEventListener("click", handleClick);

		return () => {
			document.removeEventListener("click", handleClick);
		};
	}, []);

	useEffect(() => {
		trackScreenView("Error block", document.title, {
			content: message,
		});
	}, [message]);

	return (
		<InfoPage
			icon={
				icon ?? (
					<InformationCircleMIcon color="var(--color-light-graphic-primary)" />
				)
			}
			title={title || "Произошла ошибка"}
			message={details && ENV !== "prod" ? details : message}
			actions={actions}
		/>
	);
};
