import { createContext, useContext } from "react";

type SettingsContextValue = {
	features: Array<string>;
};

export const SettingsContext = createContext<SettingsContextValue>({
	features: [],
});

export function useSettingsContext() {
	return useContext(SettingsContext);
}

export function useFeatureFlag(flag: string) {
	const { features } = useSettingsContext();

	return features.includes(flag);
}

export const SettingsProvider: React.FC<{
	features: Array<string>;
	children: React.ReactNode;
}> = ({ features, children }) => {
	return (
		<SettingsContext.Provider value={{ features }}>
			{children}
		</SettingsContext.Provider>
	);
};
