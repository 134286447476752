import { useEffect, useState } from "react";

export function useMultiClick(clicksRequired: number, delay = 500) {
	const [clickCount, setClickCount] = useState(0);
	const [isClicked, setIsClicked] = useState(false);

	useEffect(() => {
		let timer: Timer;

		if (clickCount === clicksRequired) {
			setIsClicked(true);
		}

		if (clickCount > 0) {
			timer = setTimeout(() => {
				setClickCount(0);
			}, delay);
		}

		return () => clearTimeout(timer);
	}, [clickCount, clicksRequired, delay]);

	const handleClick = () => {
		setClickCount((prev) => prev + 1);
	};

	return { isClicked, handleClick };
}
